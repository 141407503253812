import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import Popper from 'popper.js'
import App from './App'
import * as serviceWorker from './serviceWorker'
import i18n from "./i18n";

import { Provider } from 'react-redux'
import store from './store'
import {I18nextProvider} from "react-i18next";



// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

const rootElement = document.getElementById('root')
ReactDOM.render(
  <Provider store={store}>
      <I18nextProvider i18n={i18n}>
          <App/>
      </I18nextProvider>
  </Provider>, rootElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
