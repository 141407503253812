import React, { Component } from 'react'
import { connect } from 'react-redux'
import helpers from "./helpers";

class LayoutFooter extends Component {
  render() {
    return (
      <nav className={`layout-footer footer bg-${this.props.footerBg}`}>
          <div className="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
              <div className="pt-3">
                  <span className="footer-text font-weight-bolder">{helpers.getSystem()}</span>
              </div>

          </div>
      </nav>
    )
  }
}

export default connect(store => ({
  footerBg: store.theme.footerBg
}))(LayoutFooter)
