import React from 'react'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import Loader from './shared/Loader'

// Layouts
import Layout2 from "./shared/layouts/Layout2";
import helpers from "./shared/layouts/helpers";

// Lazy load component
const lazy = (cb) => loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> });

// ---
// Default application layout

export const DefaultLayout = Layout2;

// ---
// Document title template

export const titleTemplate = '%s - ' + helpers.getSystem();

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object

export const defaultRoute = '/';
export const routes = [
  {
    path: '/',
    component: lazy(() => import('./components/Dashboard'))
  },
  {
    path: '/hour/registrations',
    component: lazy(() => import('./components/HourRegistrations'))
  }
];
