import React, { Component } from 'react'
import {Form, Button, Row, Col, Card} from 'react-bootstrap'
import axios from "axios";
import Loader from "../shared/Loader";
import helpers from "../shared/layouts/helpers";
import Swal from "sweetalert2";
import AuthService from '../services/auth-service';
import {withTranslation} from "react-i18next";

class Login extends Component {
    constructor(props) {
        super(props);
        document.body.style.background = "#243449";
        if (!AuthService.getCurrentUser() && (window.location.pathname).length > 1) {
           window.location = "/";
        }
        document.title = "Employee Portal | " + helpers.getSystem()
        this.state = {
            username: "",
            password: "",
            rememberMe: "",
            loading: false,
            error: false,
            host: window.location.hostname.includes("refurbee") ? "refurbee" : "mentech",
        };



        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true, error: false});

        AuthService.login(this.state.username, this.state.password, this.state.country).then(
            () => {
                window.location.reload();
            },
            error => {
                Swal.fire(
                    this.props.t("Invalid credentials!"),
                    this.props.t("Please check your username and password."),
                    'error',
                );

                this.setState({
                    loading: false,
                });
            }
        );
    };

    componentDidMount() {
        axios.get('https://ipapi.co/json/').then((response) => {
                let data = response.data;
                if (helpers.isValidCountry(data.country_code)) {
                    this.setState({country: data.country_code})
                }
        }).catch(error => {
            console.log(error)
        });

    }

    render() {
        return (
            <div className="authentication-wrapper authentication-1 px-4">
                {this.state.loading && <Loader/>}

                <div className="authentication-inner py-5">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="ui-w-60">
                            <div className="w-250 position-relative text-center" style={{ paddingBottom: '40%' }}>
                                <img style={{width: '250px', marginLeft: '-90px'}} src={helpers.getLogo()}/>
                                <small as={"h6"} className={"text-white mt-2"}><strong>[EMPLOYEE]</strong></small>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                        <div className="col-md-4 my-5">
                            <Card>
                                <Card.Body>
                                    <form onSubmit={this.handleSubmit}>
                                        <Form.Group>
                                            <Form.Label>{this.props.t("Username")}</Form.Label>
                                            <Form.Control name="username" value={this.state.username} onChange={this.handleInputChange}/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label className="d-flex justify-content-between align-items-end">
                                                <div>{this.props.t("Password")}</div>
                                            </Form.Label>
                                            <Form.Control name="password" type="password" value={this.state.password} onChange={this.handleInputChange}/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label className="d-flex justify-content-between align-items-end">
                                                <div>{this.props.t("Connect to")}</div>
                                            </Form.Label>
                                            <Form.Control required as={"select"} name={"country"} value={this.state.country} onChange={this.handleInputChange} defaultValue={""}>
                                                <option disabled value={""}>{this.props.t("Please select an option")}</option>
                                                {JSON.parse(process.env.REACT_APP_API_LIST)[0][this.state.host].map(item => (
                                                    <option key={item.code} value={item.code}>{item.country}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <div className="d-flex justify-content-between align-items-center m-0">
                                            <Form.Check type="checkbox" name="rememberMe" custom checked={this.state.rememberMe} onChange={this.handleInputChange} label={this.props.t("Remember me")} className="m-0" id="login-remember-me"/>
                                            <Button type={"submit"} variant="primary">{this.props.t("Sign In")}</Button>
                                        </div>
                                    </form>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}

export default withTranslation()(Login);
