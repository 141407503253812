import React, { Component } from 'react'
import { BrowserRouter as AppRouter, Route, Switch, Redirect } from 'react-router-dom'
import NotFound from './NotFound'

// Routes
import { DefaultLayout, titleTemplate, defaultRoute, routes } from '../routes'
import helpers from "./layouts/helpers";
import Login from "../components/Login";

// ---
// Main route component

class AnonymousRouter extends Component {
  constructor(props) {
    super(props);

  }


  render() {
    return (
      <AppRouter >
        <Switch>
           <Route
              path={"/"}
              exact={true}
              render={props => {
                return <Login {...props}/>
              }}
              key={"/"}
            />
          <Route path="*" component={Login} />
        </Switch>
      </AppRouter>
    )
  }
}

export default AnonymousRouter
