import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Sidenav from '../../vendor/libs/sidenav'
import helpers from './helpers'
import SidenavDivider from "../../vendor/libs/sidenav/SidenavDivider";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";

class LayoutSidenav extends Component {
  layoutSidenavClasses() {
    let bg = this.props.sidenavBg

    if (this.props.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
      bg = bg
        .replace(' sidenav-dark', '')
        .replace(' sidenav-light', '')
        .replace('-darker', '')
        .replace('-dark', '')
    }

    return `bg-${bg} ` + (
      this.props.orientation !== 'horizontal'
        ? 'layout-sidenav'
        : 'layout-sidenav-horizontal container-p-x flex-grow-0'
    )
  }

  toggleSidenav(e) {
    e.preventDefault();
    helpers.toggleCollapsed();
  }

  isMenuActive(url) {
    return this.props.location.pathname.indexOf(url) === 0
  }

  isMenuOpen(url) {
    return this.props.location.pathname.indexOf(url) === 0 && this.props.orientation !== 'horizontal'
  }

  render() {
    return (
      <Sidenav orientation={this.props.orientation} className={this.layoutSidenavClasses()}>
        <React.Fragment>
            <div className="app-brand demo" style={{paddingTop: '25px', paddingBottom: '25px'}}>

                <span className="app-brand-logo demo">
                    <img style={{width: '145px'}} src={helpers.getLogo()}/>
                </span>

                <Link to="/" className="app-brand-text demo sidenav-text font-weight-normal ml-2"></Link>
                <a href="#toggle" className="layout-sidenav-toggle sidenav-link text-large ml-auto" onClick={this.toggleSidenav}>
                    <i className="ion ion-md-menu align-middle"></i>
                </a>
            </div>
        <Sidenav.Divider className="mt-0" />
        </React.Fragment>

        {/* Inner */}
        <div className={`sidenav-inner ${this.props.orientation !== 'horizontal' ? 'py-1' : ''}`}>
            <Sidenav.RouterLink to="/"  exact={true} icon="ion ion-ios-speedometer">{this.props.t("Dashboard")}</Sidenav.RouterLink>
            {false && <Sidenav.RouterLink to="/sick" exact={true} icon="fas fa-hospital">{this.props.t("Indsend sygemelding")}</Sidenav.RouterLink>}
            <Sidenav.RouterLink to="/hour/registrations" exact={true} icon="fas fa-clock">{this.props.t("Hour registrations")}</Sidenav.RouterLink>
        </div>
      </Sidenav>
    )
  }
}

LayoutSidenav.propTypes = {
  orientation: PropTypes.oneOf(['vertical', 'horizontal'])
}

LayoutSidenav.defaultProps = {
  orientation: 'vertical'
}

export default withTranslation()(connect(store => ({
    sidenavBg: store.theme.sidenavBg
}))(LayoutSidenav))
